import React from "react";
import { socialLinks } from "../../helpers";
import {
  TalentContainer,
  Text,
  TextContent,
  Button,
  SocialLinks,
  SocialLink,
  SocialMediaButton,
} from "./contact.styles";

export const JoinTalentDatabase = () => {
  const talentDatabaseFormUrl =
    "https://docs.google.com/forms/d/15Pfb2MtnSKqUMKDIgHtsv-0PcOjKS7-2T0qlaA-qeCg/edit";
  return (
    <TalentContainer>
      <Text>Join our talent database</Text>
      <TextContent>
        We’re always looking for talented people to join our team.
      </TextContent>
      <Button
        onClick={() => window.open(talentDatabaseFormUrl, "_blank")}
        aria-label="Go to form to join our talent database"
      >
        Register
      </Button>

      <Text>Follow us</Text>
      <SocialLinks>
        {socialLinks.map((link, key) => {
          return (
            <SocialMediaButton
              onClick={() => window.open(link.url, "_blank")}
              aria-label={`Click the ${link.alt} to connect with us on the platform`}
            >
              <SocialLink
                src={link.src.light}
                alt={link.alt}
                key={key}
                aria-hidden="true"
              />
            </SocialMediaButton>
          );
        })}
      </SocialLinks>
    </TalentContainer>
  );
};

import { Dispatch, SetStateAction } from "react";
import { socialLinks } from "../../helpers";
import {
  MobileNavLinks as NavLinks,
  MobileMenuItem as NavLink,
  SocialLinks,
  SocialLink,
  SocialMediaButton,
  DownArrow,
  UpArrow,
  MenuItemDropDownContainer,
  MenuItemAndIcon,
  ServicePagesNavLinks,
  Button,
  ServicesMenuItem,
  MobileDropdownMenuItem,
} from "./navbar.styles";
import { vectorArrow } from "../../assets";
import { useState } from "react";

interface MobileNavProps {
  setIsMenuIconClicked: Dispatch<SetStateAction<boolean>>;
}

export const MobileNav = ({ setIsMenuIconClicked }: MobileNavProps) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  return (
    <>
      <NavLinks direction="left">
        <MenuItemDropDownContainer>
          <MenuItemAndIcon>
            <ServicesMenuItem
              to="/software-services"
              onClick={() => setIsMenuIconClicked(false)}
            >
              Software services
            </ServicesMenuItem>
            <Button onClick={() => setOpenDropdown(!openDropdown)}>
              {openDropdown ? (
                <UpArrow src={vectorArrow} alt="Click to expand menu" />
              ) : (
                <DownArrow src={vectorArrow} alt="Click to expand menu" />
              )}
            </Button>
          </MenuItemAndIcon>
          {openDropdown && (
            <ServicePagesNavLinks direction="down">
              <MobileDropdownMenuItem
                to="/software-development"
                onClick={() => setIsMenuIconClicked(false)}
              >
                Software development
              </MobileDropdownMenuItem>
              <MobileDropdownMenuItem
                to="/digital-marketing"
                onClick={() => setIsMenuIconClicked(false)}
              >
                Digital marketing
              </MobileDropdownMenuItem>
              <MobileDropdownMenuItem
                to="/product-engineering"
                onClick={() => setIsMenuIconClicked(false)}
              >
                Product engineering
              </MobileDropdownMenuItem>
              <MobileDropdownMenuItem
                to="/accessibility-audit"
                onClick={() => setIsMenuIconClicked(false)}
              >
                Accessibility audit
              </MobileDropdownMenuItem>
            </ServicePagesNavLinks>
          )}
        </MenuItemDropDownContainer>
        <NavLink
          to="/customer-stories"
          onClick={() => setIsMenuIconClicked(false)}
        >
          Customer stories
        </NavLink>
        <NavLink to="/contact" onClick={() => setIsMenuIconClicked(false)}>
          Contact
        </NavLink>
      </NavLinks>
      <SocialLinks>
        {socialLinks.map((link, key) => {
          return (
            <SocialMediaButton onClick={()=> window.open(link.url)}>
              <SocialLink src={link.src.light} alt={link.alt} key={key} />
            </SocialMediaButton>
          );
        })}
      </SocialLinks>
    </>
  );
};

import React, { useEffect, useState } from "react";
import {
  Nav,
  LogoContainer,
  Logo,
  NavLinks,
  MenuItem as NavLink,
  MenuItemAndIcon,
  DesktopDownArrow as DownArrow,
  DesktopUpArrow as UpArrow,
  DesktopButton,
  MenuItemDropDownContainer,
  DropDownLinks,
  LogoNavLink,
} from "./navbar.styles";
import {
  rolkLogoDark,
  rolkLogoLight,
  vectorArrow,
  vectorArrowLight,
} from "../../assets";
import { Dispatch } from "react";
import { SetStateAction } from "react";
import { Squash as Hamburger } from "hamburger-react";

type ValidRouteProps = {
  isValidRoute: boolean;
  isMobile: boolean;
  onMenuIconClick: () => void;
  setIsMenuIconClicked: Dispatch<SetStateAction<boolean>>;
  isMenuIconClicked: boolean;
};

export const NavBar = ({
  isValidRoute,
  isMobile,
  onMenuIconClick,
  isMenuIconClicked,
  setIsMenuIconClicked,
}: ValidRouteProps) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [navLinkHovered, setNavLinkHovered] = useState<boolean>(false);
  const displayDarkLogoNav = isScrolled || !isValidRoute || isMenuIconClicked 

const handleNavLinkClick = () => {
  setNavLinkHovered(false)
  setOpenDropdown(false)
}

  const displayMobileNavIcon = () => {
    return (
      isMobile && (
        <Hamburger
          toggled={isMenuIconClicked}
          toggle={setIsMenuIconClicked}
          color={displayDarkLogoNav ? "#150d6b" : "#fff"}
        />
      )
    );
  };

  // check if page has been scrolled
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Nav isScrolled={isScrolled} isValidRoute={isValidRoute}>
      <LogoContainer>
        <LogoNavLink to="/" isScrolled={isScrolled} isValidRoute={isValidRoute}>
          <Logo
            onClick={() => setIsMenuIconClicked(false)}
            src={
              displayDarkLogoNav || (isMobile && isMenuIconClicked)
                ? rolkLogoDark
                : rolkLogoLight
            }
            alt="Rolk consulting logo"
            title="Click to go to the home page"
          />
        </LogoNavLink>
      </LogoContainer>

      {isMobile ? (
        displayMobileNavIcon()
      ) : (
        <NavLinks isScrolled={isScrolled} isValidRoute={isValidRoute}>
          <MenuItemDropDownContainer>
            <MenuItemAndIcon isScrolled={isScrolled}>
              <NavLink
                to="/software-services"
                isScrolled={isScrolled}
                isValidRoute={isValidRoute}
                onMouseEnter={() => setNavLinkHovered(true)}
                onClick={() => handleNavLinkClick()}
              >
                Software services
              </NavLink>
              <DesktopButton
                onClick={() => setOpenDropdown(!openDropdown)}
                isMobile={isMobile}
              >
                {openDropdown || navLinkHovered ? (
                  <UpArrow
                    src={displayDarkLogoNav ? vectorArrow : vectorArrowLight}
                    alt="collapse dropdown menu"
                    onClick={() => handleNavLinkClick()}
                  />
                ) : (
                  <DownArrow
                    src={displayDarkLogoNav ? vectorArrow : vectorArrowLight}
                    alt="expand dropdown menu"
                  />
                )}
              </DesktopButton>
            </MenuItemAndIcon>
            {(openDropdown || navLinkHovered) && (
              <DropDownLinks
                isScrolled={isScrolled}
                isValidRoute={isValidRoute}
                onMouseEnter={() => setNavLinkHovered(true)}
                onMouseLeave={() => setNavLinkHovered(false)}
              >
                <NavLink
                  to="/software-development"
                  onClick={() => handleNavLinkClick()}
                >
                  Software development
                </NavLink>
                <NavLink
                  to="/digital-marketing"
                  onClick={() => handleNavLinkClick()}
                >
                  Digital marketing
                </NavLink>
                <NavLink
                  to="/product-engineering"
                  onClick={() => handleNavLinkClick()}
                >
                  Product engineering
                </NavLink>
                <NavLink
                  to="/accessibility-audit"
                  onClick={() => handleNavLinkClick()}
                >
                  Accessibility audit
                </NavLink>
              </DropDownLinks>
            )}
          </MenuItemDropDownContainer>

          <NavLink
            to="/customer-stories"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => handleNavLinkClick()}
          >
            Customer stories
          </NavLink>
          <NavLink
            to="/contact"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => handleNavLinkClick()}
          >
            Contact
          </NavLink>
        </NavLinks>
      )}
    </Nav>
  );
};

import { useState } from "react";

import {
  Container,
  Heading,
  MainText,
  TextContent,
  Circle,
  TextContainer,
  AnotherSection,
  CustomerStoriesContainer,
  Story,
  Description,
  StoryImage,
  LoadMoreButton,
  Button,
} from "./customer-stories.styles.ts";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/footer";
import {
  slantedLineSeparatorDark,
  slantedLineSeparator,
  rolkLogoLight,
} from "../../assets";
import { Separator } from "../software-services/software-technology.styles";
import { ContactMini } from "../../components/contact-mini";
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import { initialStories, extraStories } from "../../helpers";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";

export const CustomerStories = () => {
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const navigate = useNavigate();

    const { width } = useViewportDimensions();
    const isMobile = width <= 990;

  return (
    <>
      <Container>
        <Circle />
        <TextContainer>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <Heading>Our work</Heading>
          </Fade>
          <Slide triggerOnce>
            <MainText>
              {isMobile ? "Client stories" : "Customer stories"}
            </MainText>
          </Slide>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <TextContent>
              Transitioning to a digital landscape holds the potential for
              heightened customer value, substantial boosts in efficiency, and
              an expanded market presence. However, it also necessitates the
              integration of cutting-edge tools into the existing framework of
              culture, expertise, and operational procedures. Our collection of
              customer narratives chronicles every phase of the digital
              transformation, from inquiry to resolution.
            </TextContent>
          </Fade>
        </TextContainer>
      </Container>
      <AnotherSection>
        <CustomerStoriesContainer>
          {initialStories.map((story, key) => {
            return (
              <>
                <Zoom key={key} delay={key * 100} triggerOnce>
                  <Story key={key}>
                    <StoryImage
                      src={story.src}
                      onClick={() => navigate("/customer-stories/story")}
                      alt={story.alt}
                    />
                    <Button
                      onClick={() => navigate("/customer-stories/story")}
                      aria-label={`Click to read more about the ${story.type} done for ${story.title}`}
                    >
                      <Description
                        onClick={() => navigate("/customer-stories/story")}
                        aria-label={`Click to read more about the ${story.type} done for ${story.title}`}
                      >
                        {story.shortDescription}
                      </Description>
                    </Button>
                  </Story>
                </Zoom>
              </>
            );
          })}
        </CustomerStoriesContainer>

        {loadMore && (
          <LoadMoreButton
            onClick={() => setLoadMore(false)}
            aria-label="Click to load more customer stories"
          >
            Load more
          </LoadMoreButton>
        )}

        {!loadMore && (
          <CustomerStoriesContainer>
            {extraStories.map((story, key) => {
              return (
                <>
                  <Zoom key={key} delay={key * 100} triggerOnce>
                    <Story>
                      <StoryImage
                        src={story.src}
                        alt={story.alt}
                        onClick={() => navigate("/customer-stories/story")}
                      />
                      <Button
                        onClick={() => navigate("/customer-stories/story")}
                        aria-label={`Click to read more about the ${story.type} done for ${story.title}`}
                      >
                        <Description>{story.shortDescription}</Description>
                      </Button>
                    </Story>
                  </Zoom>
                </>
              );
            })}
          </CustomerStoriesContainer>
        )}
      </AnotherSection>
      <Separator src={slantedLineSeparator} alt="" />
      <ContactMini color="#f7f8fc" />
      <Footer
        separator={slantedLineSeparatorDark}
        color="#f7f8fc"
        logo={rolkLogoLight}
      />
    </>
  );
};

import { homeImage } from "../../assets";
import { ServicePage } from "../../components/service-page";
import { softwareDevelopmentOfferings, resourceOfferings } from "../../helpers";

export const SoftwareDevelopment = () => (
  <ServicePage
    mainHeading="Your Path to Software Brilliance"
    serviceTitle="Software Development"
    bannerText="Accelerate Your Business With Great Software"
    sectionOneHeading="Secure a competitive advantage using advanced software solutions"
    sectionOneContent="Our objective is to generate business value for our clients, and technology is the catalyst enabling us to achieve this. We serve as an amplifying force, delivering top-tier development capabilities through teams that foster the essential engineering culture organizations require to maintain a competitive edge. Our unwavering commitment to quality guarantees a consistent and sustainable rhythm of software delivery from inception to completion"
    sectionTwoMainHeading="How we help"
    sectionTwoSubHeading="Software Challenges"
    sectionTwoContent="Whether you're seeking to build a state-of-the-art cloud-native web application or modernize outdated software, our strengths align perfectly with what matters most in the world of web development: increasing conversion rates, enhancing user experiences, and speeding up innovation."
    serviceOfferings={softwareDevelopmentOfferings}
    sectionThreeMainHeading="Creating digital leaders"
    sectionThreeSubHeading="Pioneering Innovation"
    sectionThreeContent="Our perspective on technology sees it as a pathway to achieving tangible business advantages for our clients. It's a valuable instrument in our pursuit of this mission. When selecting the right solutions for your business, we conduct a thorough evaluation of the unique challenges you face and the existing skills within your organization. With our broad expertise encompassing various tools, we not only enjoy leveraging our knowledge but also take pride in sharing it with your teams."
    resourceOfferings={resourceOfferings}
    customerStoryImage={homeImage}
    customerStorySubHeading="Unlocking AI WhatsApp Excellence: Elevating Adoption through a Professional, Secure, and Sleek Website"
    customerStoryContent="Askvia drives adoption of its AI Whatsapp app with professional, secure and sleek website"
  />
);

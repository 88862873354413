import React from "react";
import { useNavigate } from "react-router-dom";
import {
  wavesSeparator,
  lineSeparator,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  homeImage,
  rolkLogoLight,
} from "../../assets";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { extraStories, values } from "../../helpers";
import {
  Container,
  MainHeading,
  SubHeading,
  Section,
  BottomSection,
  SectionContainer,
  Separator,
  TextContainer,
  StoryImage,
  Text,
  TextContent,
  AnotherSection,
  CustomerStoriesContainer,
  Story,
  AnotherImage,
  Type,
  Description,
  ValueDescription,
  ImageContainer,
  Circle,
  SeeMoreButton,
  BottomSeparator,
  ValuesContainer,
  ValueContainer,
  ValueText,
  ValueIcon,
  Value,
} from "./home.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Background />
      <Container>
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <MainHeading>Creating digital leaders</MainHeading>
        </Fade>
        <Slide triggerOnce>
          <SubHeading>
            Empowering leaders through digital transformation
          </SubHeading>
        </Slide>
      </Container>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff">
        <Section color="#fff" templateColumns="1fr 1fr">
          <Slide direction="left" triggerOnce>
            <Text>
              Creating digital leaders by providing innovative solutions
            </Text>
          </Slide>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <TextContent>
              Rolk Consulting stands as a cutting-edge worldwide software
              engineering and IT advisory firm, dedicated to providing
              tailor-made digital solutions to enhance your team's efficiency,
              effectiveness, and overall workflow.
              <br />
              <br />
              The ongoing progression of IT services remains at the core of
              technological and organizational transformation. Our mission is to
              truly impact our clients' businesses by not only crafting the
              ideal solution but by crafting it in alignment with your unique
              business requirements. Whether it's in the realm of cloud
              computing, design, or data engineering, we empower your teams to
              expedite your digital aspirations.
            </TextContent>
          </Fade>
        </Section>
        <Separator src={lineSeparator} alt="" />
        <AnotherSection>
          <Text>Customer Stories</Text>
          <CustomerStoriesContainer>
            {extraStories.map((story, key) => {
              return (
                <>
                  <Zoom key={key} delay={key * 100} triggerOnce>
                    <Story key={key}>
                      <StoryImage
                        src={story.src}
                        alt={story.type}
                        title={story.title}
                        onClick={() => navigate("/customer-stories/story")}
                      />
                      <Type>{story.type}</Type>
                      <Description
                        onClick={() => navigate("/customer-stories/story")}
                        aria-label={story.title}
                      >
                        {story.shortDescription}
                      </Description>
                    </Story>
                  </Zoom>
                </>
              );
            })}
          </CustomerStoriesContainer>
          <SeeMoreButton
            onClick={() => navigate("/customer-stories")}
            aria-label="Click to go to the customer stories page"
          >
            View more
          </SeeMoreButton>
        </AnotherSection>
        <Separator src={lineSeparator} alt="" />
        <Section color="#fff">
          <ValueText>Our values</ValueText>
          <ValuesContainer>
            {values.map((value, key) => {
              return (
                <>
                  <Zoom key={key} triggerOnce>
                    <ValueContainer key={key}>
                      <ValueIcon
                        src={value.src}
                        onClick={() => navigate("/customer-stories")}
                        alt=""
                      />
                      <Value>{value.value}</Value>
                      <ValueDescription>{value.desc}</ValueDescription>
                    </ValueContainer>
                  </Zoom>
                </>
              );
            })}
          </ValuesContainer>
        </Section>
        <Separator src={slantedLineSeparator} alt="" />
        <SectionContainer color="#F7F8FC">
          <BottomSection color="#F7F8FC" templateColumns="1fr 1fr">
            <Fade triggerOnce>
              <ImageContainer>
                <Circle />
                <AnotherImage
                  src={homeImage}
                  alt="Man programming on computer"
                />
              </ImageContainer>
            </Fade>
            <Slide direction="right" triggerOnce>
              <TextContainer>
                <Text>Your dependable partner for digital transformation</Text>
                <TextContent>
                  At Rolk Consulting, we aim to be your reliable partner,
                  accompanying you every step of the way. With our extensive
                  consultant team, we strive to excel in providing a
                  comprehensive range of services, including business analysis,
                  visual and UX design, quality assurance, and more.
                  <br />
                  <br />
                  Our approach centers around people, emphasizing a human touch.
                  We don't just craft tailored digital solutions for your
                  enterprise; we also ensure your team is equipped to harness
                  these solutions for optimal efficiency. In this process, we
                  bring about transformations in both businesses and their
                  workforce. Our commitment to making a positive impact on our
                  clients continues to drive our ongoing innovation.
                </TextContent>
                {/* <Button
                  onClick={() => navigate("/about-us")}
                  isHovered={isHovered}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  aria-label="Click to go to the about us page"
                >
                  Read more
                </Button> */}
              </TextContainer>
            </Slide>
          </BottomSection>
        </SectionContainer>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <ContactMini />
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={rolkLogoLight}
        />
      </SectionContainer>
    </>
  );
};

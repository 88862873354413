import styled, { css } from "styled-components";
import { Zoom } from "react-awesome-reveal";

interface StyleProps {
  color: string;
  templateColumns?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 56rem;
  color: #fff;
  height: 70vh;
  justify-content: center;
  padding: 36px;
`;

export const ZoomAnimationContainer = styled(Zoom)`
  display: flex;
  justify-content: center;
`;

export const AnotherHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0px;
  color: inherit;
  z-index: 1;
`;

export const HeadingSubtext = styled(AnotherHeading)`
  font-size: 1.2rem;
  margin-top: 12px;
  font-weight: 500;
`;

export const SubHeading = styled.h2`
  font-size: 4em;
  margin: 0px;
  color: inherit;
  z-index: 1;

  @media screen and (max-width: 450px) {
    font-size: 2.8em;
    width: 95%;
  }
`;

export const SectionContainer = styled.section<StyleProps>(
  ({ color }: StyleProps) => css`
    background-color: ${color};
  `
);

export const Section = styled.div<StyleProps>(
  ({ color, templateColumns }: StyleProps) => css`
    background-color: ${color};
    display: grid;
    grid-template-columns: ${templateColumns};
    align-items: center;
    padding: 36px;
    

    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr;
    }
  `
);

export const BottomSection = styled(Section)`
  @media screen and (max-width: 1140px) {
    grid-template-columns: 1fr;
  }
`;

export const AnotherSection = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;

  @media screen and (max-width: 990px) {
    justify-content: center;
  }
`;

export const StyledSeparator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
`;

export const Separator = styled(StyledSeparator)`
  top: 4px;
`;

export const BottomSeparator = styled.img`
  width: 100%;
  position: relative;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
  }
`;

export const Text = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  max-width: 80%;
  color: #150d6b;

  @media screen and (max-width: 990px) {
    max-width: 100%;
  }
`;

export const TextContent = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  color: #150d6b;
  line-height: 160%;
  margin-bottom: 1.5em;
`;

export const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #150d6b;
`;

export const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;

  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
    height: 100%;
  }
`;

export const Service = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin: 12px;
  width: 80%;
  height: 24rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 8px 8px -4px grey;
  border-radius: 4px;

  @media screen and (max-width: 990px) {
    height: fit-content;
  }
`;

export const ServiceText = styled.h4`
  font-size: 1.3rem;
  font-weight: 600;
  color: #150d6b;
  margin: 12px 0px;
`;

export const ArrowButton = styled.button`
  all: unset;
  border-radius: 50%;
  background-color: #150d6b;
  rotate: 180deg;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -8px;
  bottom: -8px;
  padding: 4px;
`;

export const Arrow = styled.img`
  padding: 12px;
  width: 24px;
`;

export const Description = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
  cursor: pointer;
  margin-bottom: 48px;
`;

export const Circle = styled.div`
  border-radius: 613px 817px 539px 675px;
  height: 500px;
  width: 500px;
  border: 2px solid #150d6b;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1140px) {
    display: none;
  }
`;
export const ImageWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    vertical-align: middle;
  }
`;

export const AnotherImage = styled(Image)`
  width: 28rem;
  border-radius: 817px 539px 675px;
  position: absolute;
  top: -30px;
  left: 90px;

  &:hover{
  transform: unset;
  cursor: unset;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: #150d6b;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid #150d6b;
  padding: 14px 40px 14px 28px;
  border-radius: 100px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }

  @media screen and (max-width: 990px) {
    align-self: center;
  }
`;

export const CustomerStoryHeading = styled.h4`
  font-size: 1.875rem;
  font-weight: 700;
  max-width: 80%;
  color: #150d6b;
  margin: 12px 0;

  @media screen and (max-width: 1140px) {
    max-width: unset;
  }
`;

export const MainHeading = styled.h1`
  font-size: 1.5rem;
  margin: 0px;
  color: inherit;
  z-index: 1;
`;

import { useNavigate } from "react-router";
import {
  ContactContainer,
  Heading,
  MainHeading,
  Button,
} from "./contact-mini.styles";

interface CustomProps {
  color?: string;
  heading?: string;
  content?: string;
}
export const ContactMini = ({ color, heading, content }: CustomProps) => {
  const navigate = useNavigate();

  return (
    <ContactContainer color={color}>
      <Heading>{heading ? heading : "Questions?"}</Heading>
      <MainHeading>{content ? content : "Get in touch"}</MainHeading>
      <Button
        onClick={() => navigate("/contact")}
        aria-label="Click this button to go to the contact us page"
      >
        Contact us
      </Button>
    </ContactContainer>
  );
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  wavesSeparator,
  lineSeparator,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  rolkLogoLight,
  arrow,
  codingPhoto,
} from "../../assets";
import { Fade, Slide } from "react-awesome-reveal";
import { softwareServices } from "../../helpers";
import {
  Container,
  MainHeading,
  SubHeading,
  AnotherHeading,
  HeadingSubtext,
  CustomerStoryHeading,
  Section,
  BottomSection,
  SectionContainer,
  ImageWrapper,
  Separator,
  TextContainer,
  Text,
  TextContent,
  AnotherSection,
  ServicesContainer,
  BottomSeparator,
  Service,
  ServiceText,
  ArrowButton,
  Arrow,
  Image,
  AnotherImage,
  Description,
  ImageContainer,
  Circle,
  Button,
  ZoomAnimationContainer as Zoom,
} from "./software-technology.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";

export const SoftwareServices = () => {
  const navigate = useNavigate();
  const [hoveredService, setHoveredService] =
    useState<number | undefined>(undefined); // State variable to track the hovered service

  // Function to handle mouse enter event
  const handleMouseEnter = (key: number) => {
    setHoveredService(key);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setHoveredService(undefined);
  };

  // check for mobile dimensions
  const { width } = useViewportDimensions();
  const isMobile = width <= 990;

  return (
    <>
      <Background />
      <Container>
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <MainHeading>Software Services</MainHeading>
        </Fade>
        <Slide triggerOnce>
          <SubHeading>Advancing High-Quality Software</SubHeading>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <HeadingSubtext>
              The Quality, Security and Speed Your Users Seek
            </HeadingSubtext>
          </Fade>
        </Slide>
      </Container>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff">
        <Section color="#fff" templateColumns="1fr 1fr">
          <Slide direction="left" triggerOnce>
            <Text>
              Delivering software of the desired quality at the optimal timing
            </Text>
          </Slide>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <TextContent>
              Distinguish yourself from your rivals, maintain user contentment,
              and draw in fresh clientele by adopting a comprehensive strategy
              to software development. This approach incorporates feedback,
              rapid development cycles, early assessments, and the appropriate
              technology, all while prioritizing security, quality, compliance,
              and adaptability.
              <br />
              <br />
              We facilitate swift and budget-conscious delivery of top-tier,
              secure goods. We aid you in addressing the challenges that all
              businesses encounter but may lack the time, resources, or internal
              expertise to resolve. We evaluate impediments, enhance your
              software delivery process, promote a collective sense of security
              responsibility, and offer training for your workforce.
            </TextContent>
          </Fade>
        </Section>
        <Separator src={lineSeparator} alt="" />
        <AnotherSection>
          <Text>Software Services</Text>
          <ServicesContainer>
            {softwareServices.map((service, key) => {
              const isServiceHovered = key === hoveredService;
              return (
                <>
                  <Zoom key={key} delay={key * 100} triggerOnce>
                    <Service
                      onClick={() => navigate(`/${service.url}`)}
                      key={key}
                      onMouseEnter={() => handleMouseEnter(key)} // Handle mouse enter event
                      onMouseLeave={handleMouseLeave} // Handle mouse leave event
                      aria-label={`Click to read more about ${service.offering}`}
                    >
                      <ImageWrapper>
                        <Image
                          src={service.src}
                          onClick={() => navigate(`/${service.url}`)}
                          alt={service.alt}
                        />
                      </ImageWrapper>
                      <ServiceText>{service.offering}</ServiceText>
                      <Description>{service.shortDescription}</Description>
                      {isServiceHovered ||
                        (isMobile && (
                          <ArrowButton
                            key={key}
                            aria-label={`Click to go to the ${service.offering} page`}
                          >
                            <Arrow src={arrow} alt="" />
                          </ArrowButton>
                        ))}
                    </Service>
                  </Zoom>
                </>
              );
            })}
          </ServicesContainer>
        </AnotherSection>
        <Separator src={slantedLineSeparator} alt="" />
        <SectionContainer color="#F7F8FC">
          <BottomSection color="#F7F8FC" templateColumns="1fr 1fr">
            <Fade triggerOnce>
              <ImageContainer>
                <Circle />
                <AnotherImage src={codingPhoto} alt="Accessibility image" />
              </ImageContainer>
            </Fade>
            <Slide direction="right" triggerOnce>
              <TextContainer>
                <AnotherHeading>Customer story</AnotherHeading>
                <CustomerStoryHeading>
                  Social enterprise launches responsive and WCAG compliant
                  career navigation platform
                </CustomerStoryHeading>
                <TextContent>
                  Explore the inspiring journey of a socially conscious
                  enterprise as they introduce a cutting-edge, responsive career
                  navigation platform that not only adapts to users' needs but
                  also meets WCAG compliance standards, ensuring inclusivity for
                  all.
                </TextContent>
                <Button
                  onClick={() => navigate("/customer-stories/story")}
                  aria-label="Click here to read more on this customer story"
                >
                  Read more
                </Button>
              </TextContainer>
            </Slide>
          </BottomSection>
        </SectionContainer>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <SectionContainer color="#fff">
          <ContactMini />
        </SectionContainer>
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={rolkLogoLight}
        />
      </SectionContainer>
    </>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
`;
export const Heading = styled.h2`
  font-size: 1.25rem;
  color: #150d6b;
  text-align: center;
`;

export const MainText = styled.h1`
  font-size: 4em;
  color: #150d6b;
  z-index: 1;
  text-align: center;
  margin:0;

  @media screen and (max-width: 450px) {
    font-size: 2.8em;
    width: 95%;
  }
`;

export const HomeButton = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: #150d6b;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid #150d6b;
  padding: 14px 40px 14px 28px;
  border-radius: 100px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }
`;

export const BottomSeparator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
  top: 0px;
  transform: scaleX(-1);
`;

export const Circle = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 613px 817px 539px 675px;
  height: 500px;
  width: 500px;
  background-color: #f7f8fc;
`;

export const TextContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
`;

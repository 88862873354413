import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const ToasterContainer = styled(ToastContainer)`
  & > div:nth-child(1) {
    background: green;
  }
`;

export const ErrorToasterContainer = styled(ToastContainer)`
  & > div:nth-child(1) {
    background: red;
  }
`;


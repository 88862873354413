import React, { useRef } from "react";
import { useForm, ValidationError } from "@formspree/react";
import {
  FormContainer,
  Fieldset,
  Legend,
  Input,
  SubmitButton,
  TextArea,
  Text,
} from "./form-styles";
import { Toaster, ErrorToast } from "../toaster/toaster";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";

export const ContactForm = () => {
  const [state, handleSubmit] = useForm("xgejpkog");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const isMounted = useRef(false);

  const notify = () => {
    if (!isMounted.current) {
      toast.info("Successfully submitted!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  const errorNotify = () => {
    if (!isMounted.current) {
      toast.info("Error submitting form", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  const clearFormInputs = () => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setCountry("");
    setReason("");
    setMessage("");
    setEmail("");
  };

  useEffect(() => {
    if (state.succeeded) {
      notify();
      clearFormInputs();
    }
  }, [state.succeeded]);

  useEffect(() => {
    if (state.errors) {
      errorNotify();
    }
  }, [state.errors]);

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fieldset>
        <Legend>Contact Rolk Consulting</Legend>
        <Input
          name="firstname"
          type="text"
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <ValidationError field="firstname" errors={state.errors} />
        <Input
          name="lastname"
          type="text"
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <ValidationError field="lastname" errors={state.errors} />
        <Input
          name="companyname"
          type="text"
          placeholder="Company name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />
        <ValidationError field="companyname" errors={state.errors} />
        <Input
          name="email"
          type="email"
          placeholder="Business email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <ValidationError field="email" errors={state.errors} />
        <Input
          name="country"
          type="text"
          placeholder="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <ValidationError field="country" errors={state.errors} />
        <Input
          name="reason"
          type="text"
          placeholder="Reason for contact"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          required
        />
        <ValidationError field="reason" errors={state.errors} />
        <TextArea
          name="message"
          placeholder="Your message or question"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <ValidationError field="message" errors={state.errors} />
        <Text>
          Rolk Consulting needs the contact information you provide to us to
          contact you about your request or question. For information on our
          privacy practices and commitment to protecting your privacy, check out
          our Privacy Policy.
        </Text>
        {state.succeeded && <Toaster />}
        {state.errors && <ErrorToast />}
        <SubmitButton disabled={state.submitting}>Send message</SubmitButton>
      </Fieldset>
    </FormContainer>
  );
};

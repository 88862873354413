import {
  Container,
  Heading,
  MainText,
  HomeButton,
  Circle,
  TextContainer,
} from "./coming-soon.styles";
import { useNavigate } from "react-router-dom";
import {JackInTheBox as Zoom} from "react-awesome-reveal";

export const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Circle />
        <Zoom triggerOnce>
          <TextContainer>
            <MainText>Coming soon</MainText>
            <Heading>
              We're busy preparing something awesome. Stay tuned!
            </Heading>

            <HomeButton onClick={() => navigate("/")}>
              Go to homepage
            </HomeButton>
          </TextContainer>
        </Zoom>
      </Container>
    </>
  );
};

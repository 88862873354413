import {
  Container,
  MainText,
  Circle,
  TextContainer,
  AnotherSection,
  Separator,
} from "./contact.styles";
import { Footer } from "../../components/footer";
import {
  slantedLineSeparatorDark,
  slantedLineSeparator,
  rolkLogoLight,
} from "../../assets";
import { Slide } from "react-awesome-reveal";
import { ContactForm } from "../../components/form";
import { JoinTalentDatabase } from "./join-talent-database";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";

export const Contact = () => {
  // check for mobile dimensions

  const { width } = useViewportDimensions();
  const isMobile = width <= 990;
  return (
    <>
      <Container>
        <Circle />
        <TextContainer>
          <Slide>
            <MainText>Get in touch</MainText>
          </Slide>
        </TextContainer>
      </Container>
      <AnotherSection>
        <ContactForm />
        {!isMobile && <JoinTalentDatabase />}
      </AnotherSection>

      <Separator src={slantedLineSeparator} alt="" />
      {isMobile && <JoinTalentDatabase />}
      <Footer
        separator={slantedLineSeparatorDark}
        color="#f7f8fc"
        logo={rolkLogoLight}
      />
    </>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  position: relative;
`;

export const MainText = styled.h1`
  font-size: 4em;
  margin: 0px;
  color: inherit;
  z-index: 1;
  padding: 36px 36px 0;

  @media screen and (max-width: 450px) {
    font-size: 2.8em;
    width: 95%;
  }
`;

export const LoadMoreButton = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: grey;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid grey;
  padding: 14px 40px 14px 28px;
  border-radius: 100px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }
`;

export const BottomSeparator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
  top: 0px;
  transform: scaleX(-1);
`;

export const Circle = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 613px 817px 539px 675px;
  height: 500px;
  width: 500px;
  background-color: #f7f8fc;
`;

export const TextContent = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  color: grey;
  line-height: 160%;
  margin-bottom: 1.5em;
  font-weight: 400;

  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #150d6b;
`;

export const StyledTextContent = styled.h2`
  font-size: 1.125rem;
  font-weight: 400;
  color: grey;
  line-height: 160%;
  margin-bottom: 1.5em;
  font-weight: 400;
`;

export const Text = styled(StyledTextContent)`
  font-weight: 600;
  color: #150d6b;
  margin: 0px;

  @media screen and (max-width: 990px) {
    font-size: 1.7rem;
  }
`;

export const AnotherSection = styled.section`
  display: flex;
  flex-direction: row;
  padding: 24px;
  position: relative;
  align-items: center;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    padding: 24px;
  }
`;

export const TalentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
    padding: 24px;
    align-items: center;
    background-color: rgb(247, 248, 252);
  }
`;

export const SocialMediaButton = styled.button`
  background-color: unset;
  border: none;
`;

export const Button = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: #150d6b;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid #150d6b;
  padding: 7px 20px 7px 14px;
  border-radius: 100px;
  font-weight: 500;
  margin-bottom: 36px;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }
`;

export const StyledSeparator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
`;

export const Separator = styled(StyledSeparator)`
  top: 4px;
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
`;

export const SocialLink = styled.img`
  width: 3rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #150d6b;
  margin-right: 4px;

  z-index: 1;

  &:hover {
    width: 3.2rem;
    transition: all 0.2s ease-in-out 0s;
    background-color: grey;
  }
`;

import { homeImage } from "../../assets";
import { ServicePage } from "../../components/service-page";
import { productEngineeringOfferings, resourceOfferings } from "../../helpers";

export const ProductEngineering = () => (
  <ServicePage
    mainHeading="Unlock Your Product Potential"
    serviceTitle="Software Product Engineering"
    bannerText="The knowledge, Expertise and Talent Essential For Your Business Success"
    sectionOneHeading="Identifying and Attracting Exceptional Global Talent for Independent Software Vendors"
    sectionOneContent="Whether you're a seasoned ISV or an enterprise venturing into software, collaborating with a partner is your time-saving accelerator. Our focus is your product. We're aware that software development is a time-intensive endeavor, demanding the latest expertise and top-notch skills. We empathize with the mounting pressure to meet deadlines, surmount obstacles, maintain speed, all while ensuring impeccable product quality."
    sectionTwoMainHeading="How we help"
    sectionTwoSubHeading="Your Software Product Challenges"
    sectionTwoContent="We expand your horizons and capabilities beyond borders. Our expertise lies in optimizing product engineering through outsourcing, nearshoring, and offshoring. We cater to diverse ambitions and industries, serving as co-creation partners for one and a full-scale software factory for another. Our adaptable services cover a wide range of technologies, including AI, ML, IoT, Cloud, Web tech, Experience design, Application Architectures, and Low Code Platforms, all delivered by our specialist team abroad."
    serviceOfferings={productEngineeringOfferings}
    sectionThreeMainHeading="Our services portfolio"
    sectionThreeSubHeading="Software Product Engineering Services"
    sectionThreeContent="We empower ISVs on their product journey, offering end-to-end product engineering services. Whether it's creating a platform, application, specialised software, or MVP, we're technology agnostic but excel in JavaScript, Typescript, React and relational databases. Size doesn't matter; it's the scope of your dreams that inspires us. Our clients, visionary software developers in their industries, have a clear vision, and we're here to turn it into reality."
    resourceOfferings={resourceOfferings}
    customerStoryImage={homeImage}
    customerStorySubHeading="Elevating Code Quality: CodeScanner.AI's MVP Powered by GPT-3.5 Turbo Gains Traction with Angel Investors"
    customerStoryContent="CodeScanner.AI gets initial product MVP into the hands of angel investors, helping to improve developer code quality using GPT 3.5 turbo"
  />
);

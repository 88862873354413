import { homeImage } from "../../assets";
import { ServicePage } from "../../components/service-page";
import { digitalMarketingServiceOfferings } from "../../helpers";

export const DigitalMarketing = () => (
  <ServicePage
    mainHeading="Elevate Your Digital Presence with SEO Excellence"
    serviceTitle="SEO Optimisation and Digital Marketing"
    bannerText="Maximising Visibility for Inclusive Digital Success"
    sectionOneHeading="Pioneering SEO and Digital Visibility"
    sectionOneContent="At the forefront of digital marketing, our mission is to amplify online visibility and inclusivity. We advocate for SEO excellence, conducting thorough audits to identify and address barriers that could hinder your digital content's reach. Our steadfast commitment ensures a user-friendly and inclusive digital experience, unlocking new heights of online success."
    sectionTwoMainHeading="How we Transform Digital Presence"
    sectionTwoSubHeading="Overcoming SEO Challenges Strategically"
    sectionTwoContent="Whether it's optimising your website, application, or digital content, we excel in pinpointing and resolving SEO challenges. Our services are customized to elevate SEO compliance, enrich user experiences, and ensure that your digital assets meet the highest SEO standards, boosting your online presence and engagement."
    serviceOfferings={digitalMarketingServiceOfferings}
    customerStoryImage={homeImage}
    customerStorySubHeading="SME Achieves SEO Compliance, Meets Market Demands"
    customerStoryContent="We collaborated with a SME to enhance the SEO performance of their web presence, ensuring seamless access for all users. Our SEO optimization and digital marketing audit have played a pivotal role in their commitment to achieving online visibility and success."
  />
);

import { useEffect, useState } from "react";
import "./App.css";
import { MobileNav, NavBar } from "./components/navbar";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  CustomerStories,
  Error,
  Home,
  SoftwareTechnology,
  Contact,
  Privacy,
} from "./pages";
import { ComingSoon } from "./pages/coming-soon";
import {
  SoftwareDevelopment,
  ProductEngineering,
  Security,
  Accessibility,
  PublicSector,
  Training,
  DigitalMarketing,
} from "./pages/service-pages";
import { useViewportDimensions } from "./hooks/viewPortDimensions";

function App() {
  const location = useLocation();
  const [isMenuIconIsClicked, setIsMenuIconClicked] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location.pathname]);

  //  check to know which style of navbar to show
  const isValidRoute =
    location.pathname === "/" ||
    location.pathname === "/home" ||
    location.pathname === "/software-services" ||
    location.pathname === "/software-development" ||
    location.pathname === "/digital-marketing" ||
    location.pathname === "/product-engineering" ||
    location.pathname === "/accessibility-audit" ||
    location.pathname === "/privacy" ||
    location.pathname === "/insight-page";

  // check for mobile dimensions

  const { width } = useViewportDimensions();
  const isMobile = width <= 728;

  const handleMenuIconClick = () => {
    setIsMenuIconClicked(!isMenuIconIsClicked);
  };

  const displayFullLengthMobileMenu = isMenuIconIsClicked && isMobile;

  // to make sure the logo and navlink caret is the correct colour when toggling between mobile and desktop
  useEffect(() => {
    if (!isMobile) {
      setIsMenuIconClicked(false);
    }
  }, [isMobile]);
  return (
    <>
      <NavBar
        isValidRoute={isValidRoute}
        isMobile={isMobile}
        onMenuIconClick={handleMenuIconClick}
        isMenuIconClicked={isMenuIconIsClicked}
        setIsMenuIconClicked={setIsMenuIconClicked}
      />
      {displayFullLengthMobileMenu ? (
        <MobileNav setIsMenuIconClicked={setIsMenuIconClicked} />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/software-services" element={<SoftwareTechnology />} />
          <Route path="/customer-stories" element={<CustomerStories />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/customer-stories/story" element={<ComingSoon />} />
          <Route
            path="/software-development"
            element={<SoftwareDevelopment />}
          />
          <Route path="/product-engineering" element={<ProductEngineering />} />
          <Route path="/security" element={<Security />} />
          <Route path="/accessibility-audit" element={<Accessibility />} />
          <Route path="/public-sector" element={<PublicSector />} />
          <Route path="/training-and-upskilling" element={<Training />} />
          <Route path="*" element={<Error />} />
        </Routes>
      )}
    </>
  );
}

export default App;

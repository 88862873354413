import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  position: relative;
  height: 70vh;
  justify-content: center;
  padding: 24px 0;
`;

export const Heading = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  color: inherit;
  z-index: 1;
`;

export const MainText = styled.h2`
  font-size: 4em;
  margin: 0px;
  color: inherit;
  z-index: 1;

  @media screen and (max-width: 990px) {
    font-size: 2.8em;
  }
`;

export const LoadMoreButton = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: grey;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid grey;
  padding: 14px 40px 14px 28px;
  border-radius: 100px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }
`;

export const BottomSeparator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
  top: 0px;
  transform: scaleX(-1);
`;

export const Circle = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 613px 817px 539px 675px;
  height: 500px;
  width: 500px;
  background-color: #f7f8fc;
`;

export const TextContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  color: #150d6b;
  padding: 36px;
`;

export const TextContent = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  color: grey;
  line-height: 160%;
  width: 75%;
  margin-bottom: 1.5em;
  font-weight: 400;

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const AnotherSection = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
`;

export const CustomerStoriesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;

  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

export const Story = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Image = styled.img`
  width: 24rem;
  cursor: pointer;
`;

export const StoryImage = styled(Image)`
  height: 16rem;
  border-radius: 4px;
  width: -webkit-fill-available;
  margin: 12px;

  @media screen and (max-width: 1250px) {
    width: 100%;
    height: 400px;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    height: auto;
  }
`;

export const Type = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  color: #150d6b;
`;

export const Description = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
  cursor: pointer;
  text-align: start;

  &:hover {
    color: grey;
  }
`;

export const Button = styled.button`
  background-color: unset;
  border: none;
  display: flex;
  padding: 0;
`;

import styled, { css } from "styled-components";

interface StyleProps {
  color?: string;
}

export const ContactContainer = styled.div<StyleProps>(
  ({ color }: StyleProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${color ? color : "#fff"};
    padding-top:12px;
  `
);

export const Heading = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  color: inherit;
  z-index: 1;
`;
export const MainHeading = styled.p`
  font-size: 1.875rem;
  font-weight: 700;
  max-width: 100%;
  color: #150d6b;
  margin: 12px 0;
`;

export const Button = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: #150d6b;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid #150d6b;
  padding: 14px 24px;
  border-radius: 100px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }
`;

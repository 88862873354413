import {
  Container,
  Heading,
  MainText,
  HomeButton,
  Circle,
  TextContainer,
} from "./error.styles";
import { useNavigate } from "react-router-dom";

export const Error = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Circle />
        <TextContainer>
          <Heading>404 error</Heading>
          <MainText>This page does not exist</MainText>
          <HomeButton onClick={() => navigate("/")}>Go to homepage</HomeButton>
        </TextContainer>
      </Container>
    </>
  );
};

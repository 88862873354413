import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  singleLineWave,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  rolkLogoLight,
  wavesSeparator,
} from "../../assets";
import { Fade, Slide } from "react-awesome-reveal";
import {
  Container,
  MainHeading,
  SubHeading as Heading,
  Section,
  ResourceSection,
  BottomSection,
  ExtraText,
  SectionContainer,
  BannerText,
  MainSubHeading,
  Separator,
  TextContainer,
  SectionTwoTextContainer,
  Text,
  TextContent,
  AnotherSection,
  SeparatorContainer,
  ResourceOffering,
  ResourceContainer,
  ResourceIcon,
  ResourceText,
  ServiceOffering,
  ServiceSubheadingText,
  Image,
  ImageContainer,
  Circle,
  Button,
  BottomSeparator,
  CustomerStoryContainer,
  CustomerStoryHeadingText,
} from "./service-page.styles";
import { Footer } from "../footer";
import { ContactMini } from "../contact-mini";
import { ServicePageProps } from "./service-page.types";
import { Background } from "../background";

export const ServicePage = ({
  mainHeading,
  serviceTitle,
  bannerText,
  sectionOneHeading,
  sectionOneContent,
  sectionTwoMainHeading,
  sectionTwoSubHeading,
  sectionTwoContent,
  sectionThreeMainHeading,
  sectionThreeSubHeading,
  sectionThreeContent,
  resourceOfferings,
  serviceOfferings,
  customerStoryImage,
  customerStorySubHeading,
  customerStoryContent,
}: ServicePageProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Background />
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <MainHeading>{mainHeading}</MainHeading>
        </Fade>
        <Slide triggerOnce>
          <Heading>{serviceTitle}</Heading>
        </Slide>
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <BannerText>{bannerText}</BannerText>
        </Fade>
      </Container>
      <SeparatorContainer>
        <Separator src={wavesSeparator} alt="" />
      </SeparatorContainer>
      <SectionContainer color="#fff">
        <Section color="#fff" templateColumns="1fr 1fr">
          <Slide triggerOnce>
            <Text>{sectionOneHeading}</Text>
          </Slide>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <TextContent>{sectionOneContent}</TextContent>
          </Fade>
        </Section>
        <Separator src={slantedLineSeparator} alt="" />
        <SectionContainer color="#F7F8FC">
          <SectionTwoTextContainer>
            <MainSubHeading>{sectionTwoMainHeading}</MainSubHeading>
            <Text>{sectionTwoSubHeading}</Text>
            <ExtraText>{sectionTwoContent}</ExtraText>
          </SectionTwoTextContainer>
          <Section color="#f7f8fc" templateColumns="1fr 1fr">
            {serviceOfferings.map((service, key) => {
              return (
                <ServiceOffering key={key}>
                  <ResourceContainer>
                    <ServiceSubheadingText>
                      {service.offering}
                    </ServiceSubheadingText>
                  </ResourceContainer>
                  <ResourceText>{service.shortDescription}</ResourceText>
                </ServiceOffering>
              );
            })}
          </Section>
        </SectionContainer>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        {resourceOfferings && (
          <BottomSection color="#fff" templateColumns="1fr">
            <MainSubHeading>{sectionThreeMainHeading}</MainSubHeading>
            <Text>{sectionThreeSubHeading}</Text>
            <ExtraText>{sectionThreeContent}</ExtraText>
            <ResourceSection color="#fff" templateColumns="1fr 1fr 1fr 1fr">
              {resourceOfferings?.map((resource, key) => {
                return (
                  <>
                    <ResourceOffering>
                      <ResourceContainer>
                        <MainSubHeading>{resource.resource}</MainSubHeading>
                        <ResourceIcon
                          src={resource.logo}
                          alt={`${resource.resource} logo`}
                        />
                      </ResourceContainer>
                      <ResourceText>{resource.shortDescription}</ResourceText>
                    </ResourceOffering>
                  </>
                );
              })}
            </ResourceSection>
          </BottomSection>
        )}
        <CustomerStoryContainer>
          <Fade triggerOnce>
            <ImageContainer>
              <Circle />
              <Image src={customerStoryImage} />
            </ImageContainer>
          </Fade>
          <Slide direction="right" triggerOnce>
            <AnotherSection>
              <TextContainer>
                <CustomerStoryHeadingText>
                  Customer story
                </CustomerStoryHeadingText>
                <Text>{customerStorySubHeading}</Text>
                <TextContent>{customerStoryContent}</TextContent>
                <Button
                  onClick={() => navigate("/customer-stories/story")}
                  isHovered={isHovered}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  Read more
                </Button>
              </TextContainer>
            </AnotherSection>
          </Slide>
        </CustomerStoryContainer>
        <Separator src={singleLineWave} />
        <ContactMini heading="More information" content="Get in touch" />
      </SectionContainer>
      <Footer
        separator={slantedLineSeparatorDark}
        color="#fff"
        logo={rolkLogoLight}
      />
    </>
  );
};

import {
  engineer,
  twitter,
  twitterWhite,
  linkedin,
  linkedinWhite,
  brandingKit,
  delivery,
  askvia,
  openai,
  accessibility,
  socialEnterprise,
  jtlTech,
  teamProductivity,
  softwareDevelopment,
  productEngineering,
  reactLogo,
  typescript,
  psql,
  openSource,
  handshake,
  innovation,
  honesty,
  collaboration,
  instagramWhite,
  instagram,
  mappa,
} from "./assets";

interface IStory {
  type: string;
  src?: string;
  title: string;
  shortDescription: string;
  alt?: string;
}

export interface IService {
  offering: string;
  shortDescription: string;
  src?: string;
  alt?: string;
  url?: string;
}

export interface IResource {
  resource: string;
  shortDescription: string;
  logo: string;
}

interface IColor {
  light: string;
  dark: string;
}

interface ISocial {
  src: IColor;
  alt: string;
  url: string;
}

interface IValue {
  value: string;
  src: string;
  desc: string;
}

export const values: IValue[] = [
  {
    value: "Client-Centricity",
    src: handshake,
    desc: "Our clients are at the heart of everything we do. We prioritize their success by delivering top-tier solutions and outstanding service",
  },
  {
    value: "Continuous Innovation",
    src: innovation,
    desc: "We foster a culture of innovation, staying at the forefront of technology to provide our clients with cutting-edge solutions",
  },
  {
    value: "Integrity and Ethics",
    src: honesty,
    desc: "We uphold the highest standards of integrity and ethics in all our interactions, ensuring transparency, trust, and honesty",
  },
  {
    value: "Team Collaboration",
    src: collaboration,
    desc: "We believe in the power of collaboration and teamwork. We leverage our collective expertise to tackle complex challenges and achieve remarkable results",
  },
];

export const initialStories: IStory[] = [
  {
    type: "App website",
    src: jtlTech,
    title: "Jump The Line",
    shortDescription:
      "Start up drives adoption of its iOS app with professional and sleek website",
    alt: "Jump the line homepage",
  },
  {
    type: "Software development",
    src: engineer,
    title: "Confidential company",
    shortDescription:
      "Award winning enterprise transforms monolith into microservices using modern technologies",
    alt: "Jump the line homepage",
  },
  {
    type: "App website",
    src: askvia,
    title: "Askvia",
    shortDescription:
      "Askvia drives adoption of its AI Whatsapp app with professional, secure and sleek website",
    alt: "Robot hand with futuristic design",
  },
  {
    type: "System upgrade",
    src: delivery,
    title: "Ring4Removals",
    shortDescription:
      "UK-Based Removals Company launches sleek Website to drive lead generation and bolster business",
    alt: "Man passing bag to delivery driver",
  },
  {
    type: "System upgrade",
    src: teamProductivity,
    title: "",
    shortDescription:
      "Anon improves team productivity by creating new headless CMS using modern technologies",
    alt: "Group of people working around a table with devices",
  },
  {
    type: "Website extension",
    src: brandingKit,
    title: "",
    shortDescription:
      "Logo, full branding kit and website provided for new startup to create a professional digital presence",
    alt: "Laptop screen with code in darkness",
  },
];

export const extraStories: IStory[] = [
  {
    type: "Informational website",
    src: socialEnterprise,
    title: "Blaq Byte",
    shortDescription:
      "Blaq Byte launches career navigation platform to help Black talent thrive in tech",
    alt: "Group of people smiling and giving a team high five",
  },
  {
    type: "Software product",
    src: openai,
    title: "Codescanner.AI",
    shortDescription:
      "CodeScanner.AI gets initial product MVP into the hands of angel investors, helping to improve developer code quality using GPT 3.5 turbo",
    alt: "Open AI logo on screen",
  },
  {
    type: "Product feature",
    src: mappa,
    title: "Mappa",
    shortDescription:
      "Mappa enhances the Affordability Calculator to optimise the onboarding process and seamlessly guide users into the complete Mappa experience",
    alt: "Keyboard with accessibility buttons",
  },
];

export const socialLinks: ISocial[] = [
  {
    src: { light: twitterWhite, dark: twitter },
    alt: "twitter icon",
    url: "https://www.twitter.com/rolkconsulting",
  },
  {
    src: { light: linkedinWhite, dark: linkedin },
    alt: "linkedin icon",
    url: "https://www.linkedin.com/in/rolk-consulting-663340294/",
  }, 
  {
    src: { light: instagramWhite, dark: instagram },
    alt: "instagram icon",
    url: "https://www.instagram.com/rolkconsulting",
  }
];

export const softwareServices: IService[] = [
  {
    src: softwareDevelopment,
    alt: "Two women programming",
    url: "software-development",
    offering: "Software Development",
    shortDescription:
      "Tailored software development solutions for your specific needs.",
  },
  {
    src: productEngineering,
    alt: "Woman looking at multiple screens",
    url: "product-engineering",
    offering: "Software Product Engineering",
    shortDescription:
      "Crafting innovative software products using modern technologies.",
  },
  {
    src: accessibility,
    alt: "Desktop screen with text promoting accessibility",
    url: "accessibility-audit",
    offering: "Accessibility Audit",
    shortDescription:
      "Comprehensive accessibility audit to ensure inclusivity for all users.",
  },
];

export const softwareTechnologyServices: IService[] = [
  {
    offering: "Software Development",
    shortDescription:
      "Tailored software development solutions for your specific needs.",
  },
  {
    offering: "Software Product Engineering",
    shortDescription:
      "Crafting innovative software products using modern technologies.",
  },
  {
    offering: "Security",
    shortDescription:
      "Enhancing cybersecurity and ensuring compliance with industry standards.",
  },
  {
    offering: "Accessibility Testing",
    shortDescription:
      "Comprehensive accessibility testing to ensure inclusivity for all users.",
  },
  {
    offering: "Public Sector",
    shortDescription:
      "Empowering the public sector with accessible and secure technology solutions.",
  },
];

export const softwareDevelopmentOfferings: IService[] = [
  {
    offering: "Enhance your development capacity with proficient teams",
    shortDescription:
      "Accelerate your product's time to market by collaborating with seasoned development teams capable of handling large-scale projects from inception to completion. We maintain technology stack neutrality, allowing us to collaborate with any framework or assist you in selecting the optimal one.",
  },
  {
    offering: "Cultivate a Winning Engineering Culture",
    shortDescription:
      "Never overlook the influence of culture. In the words of Peter Drucker, 'Culture eats strategy for breakfast.' We can act as a catalyst for your teams, enhancing the engineering mindset and showcasing our proven approach to achieving success.",
  },
  {
    offering: "Evaluating Your IT and Software Ecosystem",
    shortDescription:
      "In a world where software resources are in high demand and costs rise with diminished performance, our assessment delves into your teams, procedures, and technology platforms, providing insights to enhance efficiency.",
  },
  {
    offering: "Empowering Your Development Teams Through Skill Enhancement",
    shortDescription:
      "In the realm of IT, expertise is paramount, and having the right skills sets you apart. We're passionate about knowledge sharing! Elevate your teams by offering training and coaching from our expert professionals, whether it's upskilling or reskilling, to ensure they excel in their roles.",
  },
];

export const productEngineeringOfferings: IService[] = [
  {
    offering: "Accelerate Product Development",
    shortDescription:
      "Supercharge your product development lifecycle with our seasoned engineering teams. We specialize in crafting innovative solutions, ensuring your product reaches the market faster and stays ahead of the competition.",
  },
  {
    offering: "Cultivate Engineering Excellence",
    shortDescription:
      "Forge a culture of engineering brilliance within your organization. We understand that success is not just about technology but also about the right mindset and practices. Let us guide your teams toward excellence.",
  },
  {
    offering: "Optimize IT and Software Ecosystems",
    shortDescription:
      "Maximize the efficiency of your IT and software resources. Our assessments dive deep into your teams, processes, and technology platforms, providing actionable insights to enhance performance and productivity.",
  },
  {
    offering: "Empower Your Development Teams",
    shortDescription:
      "Equip your development teams with cutting-edge skills and knowledge. We offer tailored training and coaching, whether it's upskilling or reskilling, to ensure your teams are well-equipped to excel in software product engineering.",
  },
];

export const accessibilityAuditOfferings: IService[] = [
  {
    offering: "Accessibility Assessment and Remediation",
    shortDescription:
      "Identify and resolve accessibility issues in your digital products. Our experts conduct comprehensive audits, ensuring your websites, applications, and content are accessible to all users, including those with disabilities.",
  },
  {
    offering: "Accessibility Compliance Testing",
    shortDescription:
      "Ensure compliance with accessibility standards and regulations. We perform thorough testing and validation to meet WCAG and other accessibility guidelines, helping you avoid legal and reputational risks.",
  },
  {
    offering: "User Experience Enhancement",
    shortDescription:
      "Improve the user experience for individuals with disabilities. We focus on optimizing accessibility features, enhancing usability, and providing recommendations for a more inclusive digital experience.",
  },
  {
    offering: "Accessibility Training and Awareness",
    shortDescription:
      "Empower your teams with accessibility knowledge. We offer customized training programs to educate your staff about the importance of accessibility and how to create and maintain accessible digital content.",
  },
];

export const resourceOfferings: IResource[] = [
  {
    resource: "React",
    shortDescription:
      "We harness the power of React, a cutting-edge technology, to craft dynamic and responsive software solutions that captivate users and exceed expectations",
    logo: reactLogo,
  },
  {
    resource: "Typescript",
    shortDescription:
      "Our expertise in TypeScript empowers us to deliver software solutions with impeccable precision, ensuring a seamless user experience that consistently delights our customers",
    logo: typescript,
  },
  {
    resource: "PostgreSQL",
    shortDescription:
      "Leveraging PostgreSQL, we create resilient data solutions that drive customer success by ensuring data integrity and reliability in every project",
    logo: psql,
  },
  {
    resource: "Open source",
    shortDescription:
      "Our commitment to open-source solutions not only drives customer satisfaction but also fosters positive social impact by promoting collaboration, accessibility, and innovation within the software development community",
    logo: openSource,
  },
];

export const digitalMarketingServiceOfferings: IService[] = [
  {
    offering: "SEO Performance Analysis",
    shortDescription:
      "Evaluate and enhance the SEO performance of your digital products. Our experts conduct comprehensive audits to optimize websites, applications, and content for increased visibility and engagement.",
  },
  {
    offering: "SEO Compliance Testing",
    shortDescription:
      "Ensure compliance with SEO standards and regulations. We perform thorough testing and validation to meet industry guidelines, helping you enhance your online presence while minimizing legal and reputational risks.",
  },
  {
    offering: "User Experience Optimisation",
    shortDescription:
      "Optimize the user experience for seamless customer interactions. We focus on enhancing accessibility features, improving usability, and providing recommendations for a more engaging and inclusive digital experience.",
  },
  {
    offering: "Digital Marketing Training and Awareness",
    shortDescription:
      "Empower your teams with digital marketing expertise. We offer customised training programs to educate your staff about the latest trends, strategies, and tools in digital marketing to maximise your online impact.",
  },
];

export const accessibilityAuditResourceOfferings: IResource[] = [
  {
    resource: "React",
    shortDescription:
      "We harness the power of React, a cutting-edge technology, to craft dynamic and responsive software solutions that captivate users and exceed expectations",
    logo: reactLogo,
  },
  {
    resource: "Typescript",
    shortDescription:
      "Our expertise in TypeScript empowers us to deliver software solutions with impeccable precision, ensuring a seamless user experience that consistently delights our customers",
    logo: typescript,
  },
  {
    resource: "PostgreSQL",
    shortDescription:
      "Leveraging PostgreSQL, we create resilient data solutions that drive customer success by ensuring data integrity and reliability in every project",
    logo: psql,
  },
  {
    resource: "Open source",
    shortDescription:
      "Our commitment to open-source solutions not only drives customer satisfaction but also fosters positive social impact by promoting collaboration, accessibility, and innovation within the software development community",
    logo: openSource,
  },
];

import { homeImage } from "../../assets";
import { ServicePage } from "../../components/service-page";
import { accessibilityAuditOfferings } from "../../helpers";

export const Accessibility = () => (
  <ServicePage
    mainHeading="Your Path to Accessibility Excellence"
    serviceTitle="Accessibility Audit"
    bannerText="Unlocking Inclusive Digital Experiences"
    sectionOneHeading="Leading the Way in Digital Accessibility"
    sectionOneContent="Our goal is to promote digital inclusivity and accessibility for all. We serve as advocates for accessibility, offering comprehensive audits to identify and address barriers that may prevent individuals with disabilities from accessing your digital content. Our unwavering commitment to accessibility guarantees a user-friendly and inclusive digital experience for all users."
    sectionTwoMainHeading="How we help"
    sectionTwoSubHeading="Improving Accessibility Challenges"
    sectionTwoContent="Whether you need to assess and enhance the accessibility of your website, application, or digital content, we excel in identifying and resolving accessibility issues. Our services are tailored to boost accessibility compliance, enhance user experiences, and ensure that your digital assets meet the highest accessibility standards."
    serviceOfferings={accessibilityAuditOfferings}
    customerStoryImage={homeImage}
    customerStorySubHeading="Social enterprise becomes WCAG compliant and is meets vendor requirements to continue supplying large enterprise"
    customerStoryContent="We partnered with a SME to improve the accessibility of their microfrontends, ensuring that all users, including those with disabilities, can access their software seamlessly. Our accessibility audit and recommendations have contributed to their commitment to digital inclusivity."
  />
);

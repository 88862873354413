import { socialLinks } from "../../helpers";
import { SocialMediaButton } from "./footer.styles";
import {
  Footer,
  Logo,
  FooterContent,
  FooterText,
  SocialLinks,
  SocialLink,
  BottomNav,
  Link,
  Section,
  Separator,
} from "./footer.styles";

type FooterProps = {
  separator: string;
  color: string;
  logo: string;
};

export const FooterComponent = ({ logo, separator, color }: FooterProps) => (
  <>
    <Section color={color}>
      <Separator src={separator} alt="" />
    </Section>
    <Footer>
      <Link to="/home">
        <Logo src={logo} alt="rolk consulting logo" />
      </Link>
      <FooterContent>
        <BottomNav>
          <Link to="/software-services">Software services</Link>
          <Link to="/contact">Contact</Link>
        </BottomNav>
        <SocialLinks>
          {socialLinks.map((link, key) => {
            return (
              <>
                <SocialMediaButton
                  onClick={() => window.open(link.url, '_blank')}
                  aria-label={link.alt}
                >
                  <SocialLink
                    src={link.src.dark}
                    alt={link.alt}
                    key={key}
                    aria-hidden="true"
                  />
                </SocialMediaButton>
              </>
            );
          })}
        </SocialLinks>
        <FooterText>© Rolk Consulting 2023 | All rights reserved</FooterText>
      </FooterContent>
    </Footer>
  </>
);

import { useState, useEffect } from "react";

interface ViewportDimensions {
  width: number;
  height: number;
  isMobile: boolean;
}

const MOBILE_WIDTH_THRESHOLD = 720;
const MOBILE_HEIGHT_THRESHOLD = 728;

export const useViewportDimensions = (): ViewportDimensions => {
  const [dimensions, setDimensions] = useState<ViewportDimensions>(() => ({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile:
      window.innerWidth <= MOBILE_WIDTH_THRESHOLD &&
      window.innerHeight <= MOBILE_HEIGHT_THRESHOLD,
  }));

  useEffect(() => {
    function handleResize() {
      const isMobile =
        window.innerWidth <= MOBILE_WIDTH_THRESHOLD &&
        window.innerHeight <= MOBILE_HEIGHT_THRESHOLD;

      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return dimensions;
};

import { homeImage } from "../../assets";
import { ServicePage } from "../../components/service-page";
import { softwareDevelopmentOfferings, resourceOfferings } from "../../helpers";

export const Security = () => (
  <ServicePage
    serviceTitle="Software Development"
    bannerText="Accelerate your business with great software"
    sectionOneHeading="Secure a competitive advantage using advanced software solutions"
    sectionOneContent="Our objective is to generate business value for our clients, and technology is the catalyst enabling us to achieve this. We serve as an amplifying force, delivering top-tier development capabilities through teams that foster the essential engineering culture organizations require to maintain a competitive edge. Our unwavering commitment to quality guarantees a consistent and sustainable rhythm of software delivery from inception to completion"
    sectionTwoMainHeading="How we help"
    sectionTwoSubHeading="Your Software Challenges"
    sectionTwoContent="Whether you require the development of a new cloud-native web application, the replacement of legacy software, or temporary expertise in Kotlin or React, we excel in the areas that count the most: boosting conversion rates, enhancing user experiences, and expediting innovation."
    serviceOfferings={softwareDevelopmentOfferings}
    sectionThreeMainHeading="Creating digital leaders"
    sectionThreeSubHeading="Leading the Way in Technological Advancements"
    sectionThreeContent="We view technology as a means to an end, with our primary objective being the creation of substantial business value for our clients. Technology serves as a valuable tool in achieving this goal. In choosing the optimal solutions for your business, we carefully assess the specific challenges at hand and the skills already present within your organization. Our extensive experience spans a diverse array of tools, and we take pleasure in not only applying our expertise but also in imparting our knowledge to your teams."
    resourceOfferings={resourceOfferings}
    customerStoryImage={homeImage}
    customerStorySubHeading="Askvia drives adoption of its AI Whatsapp app with professional, secure and sleek website"
    customerStoryContent="Askvia drives adoption of its AI Whatsapp app with professional, secure and sleek websiteAskvia drives adoption of its AI Whatsapp app with professional, secure and sleek websiteAskvia drives adoption of its AI Whatsapp app with professional, secure and sleek website"
  />
);
